@import '../../../node_modules/carbon-components/scss/components/accordion/accordion';
@import '../../../node_modules/carbon-components/scss/components/ui-shell/header';
@import '../../../node_modules/carbon-components/scss/components/ui-shell/ui-shell';
@import '../../../node_modules/carbon-components/scss/components/ui-shell/header-panel';

$primary-color: white;

// Need to manually override the settings as ui-shell only allows black and other non Body coach colour scheme
.navDropdown {
  & :focus {
    border-bottom-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-top-color: transparent !important;
  }
  a {
    color: white !important;

    background-color: transparent !important;
  }
}

.navItem {
  a {
    color: white !important;
  }

  background-color: #18335b !important;
  & :hover {
    background-color: #0053c7 !important;
  }
}
