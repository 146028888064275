@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/Lato-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  src: url('../fonts/Lato-Italic.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 800;
  src: url('../fonts/Lato-Bold.ttf') format('truetype');
}
